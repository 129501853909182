import React from 'react';

function TopButtons({ toggleMenu, addNewPage, togglePopup }) {
  return (
    <div className="top-buttons">
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰ Menu
      </button>
      <button className="new-page-button" onClick={addNewPage}>
        <span className="icon">+</span>
        <span>New Page</span>
      </button>
      <button className="info-button" onClick={togglePopup}>
        {"<>"}
      </button>
    </div>
  );
}

export default TopButtons;