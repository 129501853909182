import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import './App.css';
import Note from './components/Note';
import SideDrawer from './components/SideDrawer';
import TopButtons from './components/TopButtons';
import PasskeyPrompt from './components/PasskeyPrompt';
import PopupOverlay from './components/PopupOverlay';
import InstallPrompt from './components/InstallPrompt';  // Add this line

// Initialize Supabase client
const supabase = createClient('https://fwquywcjaqthquxlevqn.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZ3cXV5d2NqYXF0aHF1eGxldnFuIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQzMjIzNjYsImV4cCI6MjAzOTg5ODM2Nn0.zlKKz_NzEDhzWrPrvGoskJ5oyxuQXmAG7srl4qqXkKc');

function App() {
  const [notes, setNotes] = useState([]);
  const [cloudSaveId, setCloudSaveId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [passkey, setPasskey] = useState('');
  const [isPasskeyActive, setIsPasskeyActive] = useState(false);
  const [enteredPasskey, setEnteredPasskey] = useState('');
  const [isPasskeyVerified, setIsPasskeyVerified] = useState(false);
  const [showPasskeyPrompt, setShowPasskeyPrompt] = useState(false);
  const [tempPasskey, setTempPasskey] = useState('');
  const [cloudNotesContent, setCloudNotesContent] = useState(''); // New state to store cloud notes content
  const [showSaveSubMenu, setShowSaveSubMenu] = useState(false);
  const [showLoadSubMenu, setShowLoadSubMenu] = useState(false);
  const [verifiedSaveIds, setVerifiedSaveIds] = useState(new Set());
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);

  // Load notes from local storage on initial render
  useEffect(() => {
    loadNotes();
    
    // Test Supabase connection
    const testSupabase = async () => {
      const { data, error } = await supabase.from('notes').select('*').limit(1);
      if (error) {
        console.error('Supabase connection test failed:', error);
      } else {
        console.log('Supabase connection successful:', data);
      }
    };
    testSupabase();
  }, []);

  useEffect(() => {
    const handleInstallPrompt = (event) => {
      if (event.data && event.data.type === 'SHOW_INSTALL_PROMPT') {
        // Instead of showing a button, we can automatically trigger the install prompt
        if (navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({ type: 'INSTALL_PWA' });
        }
      }
    };

    navigator.serviceWorker.addEventListener('message', handleInstallPrompt);

    return () => {
      navigator.serviceWorker.removeEventListener('message', handleInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    setShowInstallPrompt(false);
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({ type: 'INSTALL_PWA' });
    }
  };

  const loadNotes = () => {
    setIsLoading(true);
    const savedNotes = localStorage.getItem('notes');
    if (savedNotes) {
      setNotes(JSON.parse(savedNotes));
    } else {
      // If no saved notes, initialize with an empty array
      setNotes([]);
    }
    setIsLoading(false);
  };

  const saveLocalNotes = () => {
    const notesString = JSON.stringify(notes, null, 2);
    const blob = new Blob([notesString], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'my_notes.txt';
    link.href = url;
    link.click();
    URL.revokeObjectURL(url);
  };

  const handlePasskeyPrompt = () => {
    setShowPasskeyPrompt(true);
  };

  const saveCloudNotes = async () => {
    if (showPasskeyPrompt) {
      setPasskey(tempPasskey);
      setIsPasskeyActive(!!tempPasskey);
      setShowPasskeyPrompt(false);
    }

    try {
      console.log('Attempting to save notes to cloud...');
      const { data, error } = await supabase
        .from('notes')
        .insert({ 
          content: JSON.stringify(notes),
          passkey: passkey || null,
          keyactive: !!passkey
        })
        .select();

      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }

      console.log('Save successful, data:', data);
      const saveId = data[0].id;
      setCloudSaveId(saveId);
      alert(`Notes saved to cloud. Your save ID is: ${saveId}`);
    } catch (error) {
      console.error('Error saving to cloud:', error);
      alert('Failed to save notes to cloud. Please check the console for more details.');
    }
  };

  const cancelPasskeyPrompt = () => {
    setIsPasskeyActive(false);
    setEnteredPasskey('');
    setCloudSaveId(null);
    setCloudNotesContent('');
  };

  const loadCloudNotes = async () => {
    const saveId = prompt('Enter your save ID:');
    if (!saveId) return;

    try {
      const { data, error } = await supabase
        .from('notes')
        .select('content, passkey, keyactive')
        .eq('id', saveId)
        .single();

      if (error) throw error;

      if (data) {
        if (data.keyactive && !verifiedSaveIds.has(saveId)) {
          setIsPasskeyActive(true);
          setPasskey(data.passkey);
          setCloudSaveId(saveId);
          setCloudNotesContent(data.content);
        } else {
          setNotes(JSON.parse(data.content));
          setCloudSaveId(saveId);
          alert('Notes loaded successfully from cloud.');
        }
        setIsMenuOpen(false);
      } else {
        alert('No notes found with this save ID.');
      }
    } catch (error) {
      console.error('Error loading from cloud:', error);
      alert('Failed to load notes from cloud. Please try again.');
    }
  };

  const verifyPasskey = () => {
    if (enteredPasskey === passkey) {
      setIsPasskeyVerified(true);
      setVerifiedSaveIds(prev => new Set(prev).add(cloudSaveId));
      setNotes(JSON.parse(cloudNotesContent));
      alert('Passkey verified. You can now edit and save your notes.');
    } else {
      alert('Incorrect passkey. Please try again.');
    }
  };

  const toggleNote = (id) => {
    const toggleNoteRecursive = (notes) => {
      return notes.map(note => {
        if (note.id === id) {
          return { ...note, completed: !note.completed };
        }
        if (note.children) {
          return { ...note, children: toggleNoteRecursive(note.children) };
        }
        return note;
      });
    };
    setNotes(toggleNoteRecursive(notes));
  };

  const addChildNote = (parentId) => {
    const addChildRecursive = (notes) => {
      return notes.map(note => {
        if (note.id === parentId) {
          return {
            ...note,
            children: [...(note.children || []), { id: Date.now(), content: "New note", completed: false, children: [] }]
          };
        }
        if (note.children) {
          return { ...note, children: addChildRecursive(note.children) };
        }
        return note;
      });
    };
    setNotes(addChildRecursive(notes));
  };

  const editNote = (id, newContent) => {
    const editNoteRecursive = (notes) => {
      return notes.map(note => {
        if (note.id === id) {
          return { ...note, content: newContent };
        }
        if (note.children) {
          return { ...note, children: editNoteRecursive(note.children) };
        }
        return note;
      });
    };
    setNotes(editNoteRecursive(notes));
  };

  const deleteNote = (id) => {
    const deleteNoteRecursive = (notes) => {
      return notes.filter(note => {
        if (note.id === id) {
          return false;
        }
        if (note.children) {
          note.children = deleteNoteRecursive(note.children);
        }
        return true;
      });
    };
    setNotes(deleteNoteRecursive(notes));
  };

  const addNewPage = () => {
    const newNote = { id: Date.now(), content: "New page", completed: false, children: [] };
    setNotes(prevNotes => [...prevNotes, newNote]);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    // Collapse all submenus when opening the menu
    if (!isMenuOpen) {
      setShowSaveSubMenu(false);
      setShowLoadSubMenu(false);
    }
  };

  const loadLocalNotes = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.txt';
    input.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const loadedNotes = JSON.parse(e.target.result);
            setNotes(loadedNotes);
            alert('Notes loaded successfully from local file.');
            setIsMenuOpen(false); // Close the menu after loading
          } catch (error) {
            console.error('Error parsing local file:', error);
            alert('Failed to load notes. The file might be corrupted or in the wrong format.');
          }
        };
        reader.readAsText(file);
      }
    };
    input.click();
  };


  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleSaveSubMenu = () => {
    setShowSaveSubMenu(!showSaveSubMenu);
    setShowLoadSubMenu(false);
  };

  const toggleLoadSubMenu = () => {
    setShowLoadSubMenu(!showLoadSubMenu);
    setShowSaveSubMenu(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <SideDrawer 
        isMenuOpen={isMenuOpen}
        toggleMenu={toggleMenu}
        saveLocalNotes={saveLocalNotes}
        handlePasskeyPrompt={handlePasskeyPrompt}
        loadLocalNotes={loadLocalNotes}
        loadCloudNotes={loadCloudNotes}
        showSaveSubMenu={showSaveSubMenu}
        showLoadSubMenu={showLoadSubMenu}
        toggleSaveSubMenu={toggleSaveSubMenu}
        toggleLoadSubMenu={toggleLoadSubMenu}
      />
      <div className="main-content">
        <h1 className="title">TREES</h1>
        <TopButtons 
          toggleMenu={toggleMenu}
          addNewPage={addNewPage}
          togglePopup={togglePopup}
        />
        {cloudSaveId && <p className="cloud-save-id">Cloud Save ID: {cloudSaveId}</p>}
        <PasskeyPrompt 
          isPasskeyActive={isPasskeyActive}
          isPasskeyVerified={verifiedSaveIds.has(cloudSaveId)}
          enteredPasskey={enteredPasskey}
          setEnteredPasskey={setEnteredPasskey}
          verifyPasskey={verifyPasskey}
          showPasskeyPrompt={showPasskeyPrompt}
          tempPasskey={tempPasskey}
          setTempPasskey={setTempPasskey}
          saveCloudNotes={saveCloudNotes}
          setShowPasskeyPrompt={setShowPasskeyPrompt}
          cancelPasskeyPrompt={cancelPasskeyPrompt}
        />
        {(!isPasskeyActive || verifiedSaveIds.has(cloudSaveId)) && (
          <div className="note-list">
            {notes.length === 0 ? (
              <p className="center">No notes yet. Click "New Page" to add a note.</p>
            ) : (
              notes.map((note) => (
                <Note 
                  key={note.id} 
                  note={note} 
                  onToggle={toggleNote}
                  onAddChild={addChildNote}
                  onEdit={editNote}
                  onDelete={deleteNote}
                />
              ))
            )}
          </div>
        )}
      </div>
      <PopupOverlay 
        isPopupOpen={isPopupOpen}
        togglePopup={togglePopup}
      />
      {showInstallPrompt && (
        <div className="install-prompt">
          <p>Install this app on your device?</p>
          <button onClick={handleInstallClick}>Install</button>
        </div>
      )}
    </div>
  );
}

export default App;