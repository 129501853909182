import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// Add this function at the top of the file
function isLocalNetwork(hostname) {
  return hostname === 'localhost' 
    || hostname === '127.0.0.1'
    || hostname.startsWith('192.168.')
    || hostname.startsWith('10.')
    || /^172\.(1[6-9]|2[0-9]|3[0-1])\./.test(hostname);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Find the section where you're handling the install prompt
let deferredPrompt;

window.addEventListener('load', () => {
  console.log('Window loaded. Checking for service worker support...');
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch(error => {
        console.error('Service Worker registration failed:', error);
      });
  } else {
    console.warn('Service workers are not supported in this browser.');
  }

  checkInstallability();
});

window.addEventListener('beforeinstallprompt', (e) => {
  console.log('beforeinstallprompt event fired');
  e.preventDefault();
  deferredPrompt = e;
  showInstallPrompt();
});

function showInstallPrompt() {
  if (deferredPrompt) {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      deferredPrompt = null;
    });
  } else {
    console.log('Install prompt not available');
  }
}

// Check if the app is already installed
function checkInstallState() {
  if (window.matchMedia('(display-mode: standalone)').matches) {
    console.log('App is running in standalone mode (installed)');
  } else {
    console.log('App is not installed or not running in standalone mode');
  }
}

function checkInstallability() {
  if ('getInstalledRelatedApps' in navigator) {
    navigator.getInstalledRelatedApps().then(relatedApps => {
      console.log('Related installed apps:', relatedApps);
    });
  }

  if (window.matchMedia('(display-mode: standalone)').matches) {
    console.log('App is already installed and running in standalone mode');
  } else {
    console.log('App is not installed or not running in standalone mode');
  }

  if ('BeforeInstallPromptEvent' in window) {
    console.log('BeforeInstallPromptEvent is supported');
  } else {
    console.log('BeforeInstallPromptEvent is not supported');
  }
}

function checkManifest() {
  const manifestLink = document.querySelector('link[rel="manifest"]');
  if (manifestLink) {
    fetch(manifestLink.href)
      .then(response => response.json())
      .then(manifest => {
        console.log('Manifest successfully loaded:', manifest);
      })
      .catch(error => {
        console.error('Error loading manifest:', error);
      });
  } else {
    console.error('Manifest link not found in the document');
  }
}

function checkServiceWorkerState(registration) {
  if (registration.installing) {
    console.log('Service worker installing');
  } else if (registration.waiting) {
    console.log('Service worker installed but waiting');
  } else if (registration.active) {
    console.log('Service worker active');
  }

  registration.addEventListener('statechange', (event) => {
    console.log('Service worker state changed:', event.target.state);
  });
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('SW registered: ', registration);
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError);
    });
  });
}
