import React from 'react';
import '../styles/SideDrawer.css';

function SideDrawer({ isMenuOpen, toggleMenu, saveLocalNotes, handlePasskeyPrompt, loadLocalNotes, loadCloudNotes, showSaveSubMenu, showLoadSubMenu, toggleSaveSubMenu, toggleLoadSubMenu }) {
  return (
    <div className={`side-drawer ${isMenuOpen ? 'open' : ''}`}>
      <button className="menu-button" onClick={toggleMenu}>×</button>
      <button className="menu-button">Edit</button>
      <div className="submenu-container">
        <button className="menu-button" onClick={toggleSaveSubMenu}>Save</button>
        {showSaveSubMenu && (
          <div className="submenu">
            <button className="submenu-button" onClick={saveLocalNotes}>Save Local</button>
            <button className="submenu-button" onClick={handlePasskeyPrompt}>Save Cloud</button>
          </div>
        )}
      </div>
      <div className="submenu-container">
        <button className="menu-button" onClick={toggleLoadSubMenu}>Load</button>
        {showLoadSubMenu && (
          <div className="submenu">
            <button className="submenu-button" onClick={loadLocalNotes}>Load Local</button>
            <button className="submenu-button" onClick={loadCloudNotes}>Load From Cloud</button>
          </div>
        )}
      </div>
      <button className="menu-button">Settings</button>
    </div>
  );
}

export default SideDrawer;