import React, { useState, useEffect, useRef } from 'react';

function Note({ note, onToggle, onAddChild, onEdit, onDelete }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(note.content);
  const inputRef = useRef(null);

  const handleEdit = () => {
    onEdit(note.id, editedContent);
    setIsEditing(false);
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  return (
    <div className="note-item">
      <button className="delete-note" onClick={() => onDelete(note.id)}>×</button>
      {isEditing ? (
        <div className="note-edit">
          <input
            ref={inputRef}
            type="text"
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            onBlur={handleEdit}
          />
        </div>
      ) : (
        <div 
          className={`note-content ${note.completed ? 'completed' : ''}`}
          onClick={() => setIsEditing(true)}
        >
          {note.content}
        </div>
      )}
      <button className="add-child" onClick={() => onAddChild(note.id)}>+</button>
      <button className="toggle-complete" onClick={() => onToggle(note.id)}>×</button>
      {note.children && note.children.map(childNote => (
        <Note 
          key={childNote.id} 
          note={childNote} 
          onToggle={onToggle}
          onAddChild={onAddChild}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
}

export default Note;