import React from 'react';

function PopupOverlay({ isPopupOpen, togglePopup }) {
  if (!isPopupOpen) return null;

  return (
    <div className="popup-overlay" onClick={togglePopup}>
      <div className="popup-content center" onClick={(e) => e.stopPropagation()}>
        <h2>Welcome to TREES</h2>
        <p>Theres a good chance if you are reading this, I worked way too hard on this."It's just a note taking app, its not like you have to even write any of the notes" SHRUUUUUGGGGGGGGGSSSSSSS</p>
        <p>NeW FeaTurEs AS i LeaRN How To dO tHeM </p>
        <p>-Test Equals</p>
        <button className="close-popup" onClick={togglePopup}>Close</button>
      </div>
    </div>
  );
}

export default PopupOverlay;