import React from 'react';
import '../styles/PasskeyPrompt.css';

function PasskeyPrompt({ isPasskeyActive, isPasskeyVerified, enteredPasskey, setEnteredPasskey, verifyPasskey, showPasskeyPrompt, tempPasskey, setTempPasskey, saveCloudNotes, setShowPasskeyPrompt, cancelPasskeyPrompt }) {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      verifyPasskey();
    }
  };

  if (isPasskeyActive && !isPasskeyVerified) {
    return (
      <div className="passkey-dialog-overlay">
        <div className="passkey-dialog">
          <h2>Enter Passkey</h2>
          <input
            type="password"
            placeholder="Enter passkey"
            value={enteredPasskey}
            onChange={(e) => setEnteredPasskey(e.target.value)}
            onKeyPress={handleKeyPress}
            className="passkey-input"
            autoFocus
          />
          <div className="passkey-dialog-buttons">
            <button onClick={cancelPasskeyPrompt} className="cancel-button">Cancel</button>
            <button onClick={verifyPasskey} className="verify-button">Verify</button>
          </div>
        </div>
      </div>
    );
  }

  if (showPasskeyPrompt) {
    return (
      <div className="passkey-dialog-overlay">
        <div className="passkey-dialog">
          <h2>Set Passkey</h2>
          <input
            type="password"
            placeholder="Set a passkey (optional)"
            value={tempPasskey}
            onChange={(e) => setTempPasskey(e.target.value)}
            className="passkey-input"
            autoFocus
          />
          <div className="passkey-dialog-buttons">
            <button onClick={() => setShowPasskeyPrompt(false)} className="cancel-button">Cancel</button>
            <button onClick={saveCloudNotes} className="save-button">Save</button>
          </div>
        </div>
      </div>
    );
  }

  return null;
}

export default PasskeyPrompt;